import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';
// import router from './../router';

import authModule from './modules/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  modules:{
    auth: authModule,

  },
  state: {
    user: {},
    data: moment(),
    fechamento:null

  },
  mutations: {
    
  },
  actions: {
    findFechamento: async () => {
      console.log("aaaaaaaaaaa")
      if (authModule.state.user) {
        console.log('uss', authModule.state.user)
      }
    }
  }
});