import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import '@/assets/scss.scss'




import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(require('vue-moment'));

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// import VueGeolocation from 'vue-browser-geolocation';
// Vue.use(VueGeolocation);


import money from 'v-money'

// register directive v-money and component <money>
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  suffix: '',
  precision: 2,
  masked: false
})
  
Vue.mixin({
  data() {
    return {
      moneyMask: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    }
  },
  methods: {
    formatNumber() {
      return {
        dinheiroToFloat(vl) {
          if (!vl || vl == '' || vl == null) {
            vl = "0"
          }
          return parseFloat(vl.replace("R$", "").trim().replace(".", "").replace(",", "."));
        },
        floatToDinheiro(vl) {
          if (!vl) {
            vl = 0;
          }
          let val = (vl / 1).toFixed(2).replace('.', ',')
          return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        stringToFloat(vl) {
          if (!vl)
            vl = "0";          
          return parseFloat(vl.replace("R$", "").trim().replace(".", "").replace(",", "."))
        }
      }
    },
  }
})


// import EasyRefresh from 'vue-easyrefresh'
// Vue.use(EasyRefresh)


import filters from './filters/index.js';

import './registerServiceWorker'
Vue.use(filters);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
