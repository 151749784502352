import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './../store';
import Main from '../views/Main';

const Pedidos = () => import('../views/Pedidos.vue');
const Resumo = () => import('../views/Resumo.vue');
const Login = () => import('../views/Login.vue');
const Retiradas = () => import('../views/Retiradas.vue');


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Def',
    beforeEnter:(to, from, next)=>{
      
      next('Pedidos')
    }
  },
  {
    path: '/',
    component: Main,
    beforeEnter: async (to, from, next) => {
      const auth = await store.dispatch('auth/isAuthenticated').then(x => x);
      if (auth) {
        next();
      } else {
        next('login');
      }
    },
    children: [
      {
        path: '/pedidos',
        name: 'Pedidos',
        component: Pedidos
      },
      {
        path: '/realizados',
        name: 'Realizados',
        component: Pedidos
      },
      {
        path: '/resumo',
        name: 'Resumo',
        component: Resumo
      },
      {
        path: '/retiradas',
        name: 'Retiradas',
        component: Retiradas
      },
    ]
  },
  
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: async () => {
      await store.dispatch('auth/logout');
      // next('login');
      location.href = "/login";
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
