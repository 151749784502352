<template>
  <div id="app">
    <transition>
      <keep-alive>
        <!-- <div v-if="user.auth && !$store.fechamento">
          <h2>
            <b> <i class="fa fa-ban" /><br /> 
              Você não foi liberado para trabalhar na data  {{ data | moment("DD/MM/YYYY") }}
            </b>
          </h2>
        </div>
        <div v-else> -->
          <router-view></router-view>
        <!-- </div> -->
      </keep-alive>
    </transition>

    <div id="menu" v-if="user.auth">
      <div @click="menuOpen = false">
        <router-link
          to="/pedidos"
          :class="{ onMenu: $route.name == 'Pedidos' }"
        >
          <i class="fa fa-motorcycle"></i>
          Entregas
        </router-link>
        <router-link
          to="/realizados"
          :class="{ onMenu: $route.name == 'Realizados' }"
        >
          <i class="fa fa-history"></i>
          Realizadas
        </router-link>
        <router-link to="/resumo" :class="{ onMenu: $route.name == 'Resumo' }">
          <i class="fa fa-file-invoice-dollar"></i>
          Resumo
        </router-link>
      </div>

      <a to="#" @click="menuOpen = !menuOpen" :class="{ onMenu: menuOpen }">
        <!-- <i class="fa fa-power-off"></i> -->
        <i class="fa fa-list"></i>
        Menu
      </a>
    </div>
    <div class="menuList" v-if="menuOpen">
      <h5 class="text-white text-left p-2 bg-dark mb-0">
        {{ user.user.nome }}
      </h5>
      <div @click="menuOpen = false">
        <router-link to="/retiradas">
          <i class="fa fa-wallet"></i>
          Vales
        </router-link>
        <router-link to="/logout">
          <i class="fa fa-power-off"></i>
          Sair
        </router-link>
      </div>
      <div class="menuListData" @click="mudarData()" style="cursor: pointer">
        <i class="fa fa-calendar-alt" style="font-weight: normal"></i>
        {{ data | moment("DD/MM/YYYY") }}
      </div>
    </div>
    <b-modal id="modal-data" title="Alterar Data">
      <input
        type="date"
        format="DD/MM/YYYY"
        class="form-control"
        v-model="data_form"
      />
      <template #modal-footer>
        <button class="btn btn-primary" @click="mudarDataSistema()">
          <i class="fa fa-check"></i> Alterar
        </button>

        <button class="btn btn-secondary" @click="$bvModal.hide('modal-data')">
          <i class="fa fa-times"></i> Fechar
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      user: this.$store.state.auth,
      auth: this.$store.state.auth.auth,
      menuOpen: false,
      data: this.$store.state.data,
      data_form: this.$store.state.data.format("YYYY-MM-DD"),
      cordenadas: null,
      cordenadas2: null,
    };
  },
  watch: {
    "$store.state.auth": function () {
      this.user = this.$store.state.auth.user;
      this.auth = this.$store.state.auth.auth;
    },
    "$store.state.data": function () {
      this.data = this.$store.state.data;
      this.data_form = this.data.format("YYYY-MM-DD");
    },
    data: function () {
      this.data_form = this.data.format("YYYY-MM-DD");
    },
  },
  methods: {
    mudarData() {
      this.$bvModal.show("modal-data");
    },
    mudarDataSistema() {
      this.$store.state.data = moment(this.data_form);
      this.$bvModal.hide("modal-data");
    },
  },
  mounted() {
    // console.log(this.$route.name=='Pedidos')
    // console.log(this.user);
    // this.$watchLocation({
    //   enableHighAccuracy: true, //defaults to false
    // }).then((coordinates) => {
    //   this.cordenadas = coordinates;
    // });
  },
};
</script>

<style lang="scss">
.menuListData {
  color: white;
  font-size: 30px;
  width: 100%;
  display: block;
  float: left;
  padding-top: 20px;
  text-align: center;
  font-weight: bold;
}
.menuList {
  height: 220px;
  bottom: 50px;
  left: 0px;
  right: 0px;
  width: 100%;
  background: #2c3e50;
  position: fixed;
  transition: all 1s ease;
}
.menuList a {
  width: auto;
  float: left;
  width: 50%;
  height: 90px;
  text-decoration: none;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-top: 15px;
  color: #fff;
  font-size: 1rem;
  background: #16212c;
}
.menuList a i {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 0.6em;
}
#menu {
  background: #2c3e50;
  position: fixed;
  width: 100%;
  height: 50px;
  bottom: 0px;
  box-shadow: 1px 0px 16px black;
}
#menu a {
  width: auto;
  float: left;
  width: 25%;
  height: 100%;
  text-decoration: none;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  color: #fff;
  font-size: 0.7rem;
}
#menu a:hover {
  background: #1b2631;
}
#menu .onMenu {
  background: #1b2631;
}
#menu a i {
  font-size: 1rem;
  display: block;
  margin-bottom: 0.3em;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<style>
body,
html {
  width: 100%;
  height: 100%;
}
</style>
