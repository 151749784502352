import moment from 'moment'
export default function (Vue) {
    
     Vue.filter('telefoneString', (tel) => {
         if (tel != "") {
             tel = tel.substr(2);
             tel =
                 "(" +
                 tel.substring(0, 2) +
                 ") " +
                 tel.substr(2, 5) +
                 "-" +
                 tel.substr(7);
         }
         return tel;
     });
     Vue.filter('currency', (vl) => {
 
         let val = (vl / 1).toFixed(2).replace('.', ',')
         return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
 
     });

     Vue.filter('dataHistorico', (dt)=>{
        function timeSince(date) {

            var seconds = Math.floor((new Date() - date) / 1000);
          
            var interval = seconds / 31536000;
          
            if (interval > 1) {
              return Math.floor(interval) + " anos";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
              return Math.floor(interval) + " meses";
            }
            interval = seconds / 86400;
            if (interval > 1) {
              return Math.floor(interval) + " dias";
            }
            interval = seconds / 3600;
            if (interval > 1) {
              return Math.floor(interval) + " horas";
            }
            interval = seconds / 60;
            if (interval > 1) {
              return Math.floor(interval) + " minutos";
            }
            return Math.floor(seconds) + " segundos";
          }
          
          return timeSince(moment(dt).toDate());
     })

     Vue.filter('dataWpp', (dt)=>{
        let data = moment(dt);
        if(data.diff(moment(),'days')>=1){
            return data.format('DD.MM.YYYY HH:mm');
        }else{
            return data.format('HH:mm');
        }

     })
    
 }