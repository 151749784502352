// import Vue from 'vue';
import axios from '@/utils/axios';


const AuthModule = {
     namespaced: true,
     state: () => ({
          user: {},
          auth: false,
          defaultURL: 'Dashboard'

     }),
     actions: {
          logout: async ({ state }) => {
               localStorage.clear();
               await axios.get('auth/logout', {}).then(() => {
               }).catch(() => { });
               state.user = {};
               return true;
          },
          getUser: ({ state }, payload) => {
               state.user = payload;
          },
          auth: async ({ state, dispatch }, payload) => {
               return await axios.post(`/auth`, payload).then(async (result) => {
                    console.log('logado', result.data);
                    if (result.data && result.data.token) {
                         state.user.token = result.data.token;
                         localStorage.setItem('token', state.user.token);
                         state.auth = true;
                         axios.defaults.headers.common['Authorization'] = `Bearer ${state.user.token}`;
                         return await dispatch('isAuthenticated').then((res) => {
                              if (res) {
                                   return { success: true, user: result.data };
                              } else {
                                   return { success: false, user: null };
                              }
                         });

                    } else {


                         return { success: false, user: null };

                    }

               }).catch((error) => {
                    console.log("errorrrrrrrrr", error);
                    return false;
               });

          },
          isAuthenticated: async ({ state, dispatch }) => {
               state.user.token = localStorage.getItem('token');
               console.log(1, state.user)
               if (typeof (state.user.token) == "undefined" || state.user.token == null || state.user.token == "") {
                    state.auth = false;
                    return false;
               } else {

                    if (typeof (state.user) != "undefined" && state.user.nome) {
                         console.log('user ok', state.user);
                         state.auth = true;
                         return true;
                    } else {
                         axios.defaults.headers.common['Authorization'] = `Bearer ${state.user.token}`;


                         // await axios.get('empresas').then(result => {
                         //      rootState.empresas = result.data;
                         // }).catch(() => {
                         //      state.user = {};
                         //      localStorage.clear();
                         //      return false;
                         // })

                         return await axios.post('auth/me', { tipo: 'moto' }).then(async (result) => {
                              state.user = { ...result.data, ...state.user }; 3
                              console.log('user o2k', state.user);
                              dispatch('findFechamento', null, { root: true });

                              state.auth = true;

                              return true;
                         }).catch((err) => {
                              console.log("err", err);
                              state.user = {};
                              localStorage.clear();
                              return false;
                         });
                    }
               }
          },
     }

}

export default AuthModule
